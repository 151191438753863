import React from 'react'

// import {
//   FormattedMessage,
//   FormattedHTMLMessage /*, useIntl*/,
// } from 'react-intl'
import './style.scss'
// import { withPrefix } from 'gatsby'
import Navigation from '../components/navigation'

import withLayout from '../components/layout'

const NotFoundPage = ({ pageContext }) => {
  return (
    <>
      <header className="small_header">
        <div className="inline">
          <Navigation />
        </div>
      </header>
      <main>
        <section className="info inline">
          <article>
            <div>
              <h1>NOT FOUND</h1>
              <p>This page you requested doesn&#39;t exist.</p>
            </div>
          </article>
        </section>
      </main>
    </>
  )
}

const customProps = {
  localeKey: 'home', // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(NotFoundPage)
